<template>
  <div
    class="button-radio"
  >
    <input
      type="radio"
      class="real"
      :name="name"
      :checked="checked"
      :value="value"
      :disabled="disabled"
      @change="$emit('input', $event.target.value)"
    >
    <div
      class="fake"
      :class="{ '-checked': checked }"
    ></div>
  </div>
</template>

<script>
import './ButtonRadio.scss';

export default {
  name: 'ButtonRadio',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
    },
    name: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
