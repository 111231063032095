<template>
  <div>
    <div v-if="isSaved">
      <BaseHeading size="small">
        Ďakujeme
      </BaseHeading>
      <div class="container">
        <InfoBox
          variant="success"
          :icon="iconCheck"
        >
          Gratulujeme! Dobrá voľba :)<br> Vaše poistenie je už pripravené.
        </InfoBox>

        <InfoBox
          variant="info"
          size="small"
        >
          Stačí už len do 30 dní dokončiť platbu a vaše vozidlo bude chránené. Všetky potrebné dokumenty a informácie sme vám poslali aj na email.
        </InfoBox>
        <DescriptionList
          :items="tableItems"
        />
        <BaseButton
          size="small"
          variant="secondary"
          @click="downloadDocuments"
          :style="{ marginBottom: '30px' }
        ">
          Stiahnuť zmluvnú dokumentáciu
        </BaseButton>
      </div>
      <BaseHeading>
        Platba
      </BaseHeading>
      <div class="container">
        <InputOptionsRadio
          v-model="paymentMethod"
          :options="optionsPaymentMethod"
        />
      </div>
    </div>
    <div v-else>
      <BaseHeading size="small">
        Chyba
      </BaseHeading>
      <div class="container">
        <InfoBox
          variant="danger"
          :icon="iconError"
        >
          Objednávku sa nepodarilo uložiť kvôli chybe na serveri.
        </InfoBox>
        <BaseParagraph>
          {{ final.errors.join('; ') }}
        </BaseParagraph>
      </div>
    </div>
  </div>
</template>

<script>
import GET_PAYMENT_METHODS from '@/graphql/GetPaymentMethods.gql';
import START_CALCULATION_PAYMENT from '@/graphql/StartCalculationPayment.gql';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import currencyFormat from '@gds/util/currencyFormat';
import { httpEndpoint } from '@/vue-apollo';

import BaseHeading from '@gds/components/BaseHeading';
import BaseParagraph from '@gds/components/BaseParagraph';
import InfoBox from '@gds/components/InfoBox';
import DescriptionList from '@gds/components/DescriptionList';
import BaseButton from '@gds/components/BaseButton';
import InputOptionsRadio from '@gds/components/InputOptionsRadio';

import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    BaseHeading,
    BaseParagraph,
    InfoBox,
    DescriptionList,
    BaseButton,
    InputOptionsRadio,
  },
  data() {
    return {
      iconCheck: faCheckCircle,
      iconError: faExclamationCircle,
      paymentMethod: 0,
    };
  },
  apollo: {
    paymentMethodsCodelist: {
      query: GET_PAYMENT_METHODS,
      update(data) {
        const clean = data.paymentMethodsCodelist.map((item) => item.key);
        return clean;
      },
    },
  },
  computed: {
    ...mapState({
      calculation: (store) => store.calculation,
      final: (store) => store.calculation.final,
    }),
    ...mapGetters([
      'productProps',
    ]),
    isSaved() {
      return !this.final.errors && !!this.final.policyNumber;
    },
    tableItems() {
      const bankAccount = 'SK35 0200 0000 0000 4813 4112';
      return [
        {
          label: 'Variabilný symbol',
          value: this.final.policyNumber,
          isCopyable: !!this.final.policyNumber,
        },
        {
          label: 'Na úhradu',
          value: currencyFormat(this.final.price),
        },
        {
          label: 'Číslo účtu',
          value: bankAccount,
          isCopyable: true,
          valueToCopy: bankAccount.replace(/\s+/g, ''),
        },
      ];
    },
    optionsPaymentMethod() {
      const dictionary = [
        {
          value: 'ECOMM',
          label: 'Platba kartou',
          image: require('@/assets/payment-logos/cards.png'),
          wide: true,
          order: 1,
        },
        {
          value: 'GPAY',
          label: 'Google Pay',
          image: require('@/assets/payment-logos/apple-pay.png'),
          order: 2,
        },
        {
          value: 'APAY',
          label: 'Apple Pay',
          image: require('@/assets/payment-logos/google-pay.png'),
          order: 3,
        },
        {
          value: 'VUB',
          label: 'VÚB Banka',
          image: require('@/assets/payment-logos/vub-banka.png'),
          order: 4,
        },
        {
          value: 'SLOV_SPORITELNA',
          label: 'Slovenská sporiteľňa',
          image: require('@/assets/payment-logos/slsp.png'),
          order: 5,
        },
        {
          value: 'TATRABANKA',
          label: 'Tatra banka',
          image: require('@/assets/payment-logos/tatra-banka.png'),
          order: 6,
        },
        {
          value: 'VUB',
          label: 'Unicredit Bank',
          image: require('@/assets/payment-logos/unicredit-bank.png'),
          order: 7,
        },
      ];
      if (this.paymentMethodsCodelist) {
        const remapped = this.paymentMethodsCodelist.map((code) => {
          const match = dictionary.find((item) => item.value === code);
          const plain = {
            value: code,
            label: code,
            order: 10,
          };
          return match || plain;
        });
        remapped.sort((a, b) => ((a.order > b.order) ? 1 : -1));
        return remapped;
      }
      return null;
    },
    documentsUrl() {
      const base = httpEndpoint.replace('graphql', 'api');
      const { id, hash } = this.calculation;
      return `${base}/v1/calculations/${id}/${hash}/documents.zip`;
    },
  },
  methods: {
    sendTracking() {
      const dataLayer = window.dataLayer || [];
      this.$store.dispatch('sendMarketingTracking', 'sale');
      dataLayer.push({
        currencyCode: 'EUR',
        transactionId: this.final.policyNumber,
        transactionAffiliation: 'PZP Online',
        transactionTotal: this.final.price,
        transactionTax: 0,
        transactionShipping: 0,
        transactionProducts: [
          {
            category: 'PZP',
            sku: null,
            name: `PZP ${this.productProps.package}`,
            price: this.final.price,
            quantity: 1,
          },
        ],
        event: 'trackTrans',
      });
    },
    async tryProceed() {
      if (!this.paymentMethod) return;
      const payment = await this.$apollo.mutate({
        mutation: START_CALCULATION_PAYMENT,
        variables: {
          paymentMethod: this.paymentMethod,
          calculationId: this.calculation.id,
          callbackUrl: 'https://www.generali.sk/vysledok-platby/?status={status}',
        },
      });
      if (payment.data.startCalculationPayment) {
        const { startCalculationPayment } = payment.data;
        this.submitPaymentForm(startCalculationPayment);
      }
    },
    downloadDocuments() {
      window.open(this.documentsUrl);
    },
    submitPaymentForm(payment) {
      const form = document.createElement('form');
      form.method = payment.httpMethod;
      form.action = payment.url;
      form.style.display = 'none';

      payment.data.forEach((item) => {
        const input = document.createElement('input');
        input.name = item.key;
        input.value = item.value;
        form.appendChild(input);
      });
      document.body.appendChild(form);
      form.submit();
    },
  },
  mounted() {
    this.sendTracking();
    this.$sendToCI();
  },
};
</script>

<style>

</style>
