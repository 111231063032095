<template>
  <div class="input-options-radio">
    <div
      class="col"
      v-for="(option, i) in options"
      :class="cssClassesColumn(option)"
      :key="i"
    >
      <label
        class="input-options-radio-item"
        :class="cssClassesItem(option)"
      >
        <ButtonRadio
          class="radio"
          :name="name"
          :value="option.value"
          :checked="option.value === value"
          :disabled="option.disabled"
          @input="$emit('input', $event); $emit('change', $event)"
        />
        <img
          v-if="option.image"
          :src="option.image"
          :alt="option.label"
          class="image"
        >
        <span v-else>
          {{ option.label }}
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import './InputOptionsRadio.scss';
import ButtonRadio from '@gds/subcomponents/ButtonRadio';

export default {
  name: 'InputOptionsRadio',
  components: {
    ButtonRadio,
  },
  props: {
    value: {
      type: [String, Number],
    },
    options: {
      type: Array,
    },
    name: {
      type: String,
      default: () => {
        const uid = Math.round(Math.random() * 1000);
        return `radio-uid-${uid}`;
      },
    },
  },
  methods: {
    cssClassesColumn(option) {
      return {
        '-wide': option.wide,
      };
    },
    cssClassesItem(option) {
      return [
        (option.value === this.value) ? '-selected' : '',
        (option.disabled) ? '-disabled' : '',
      ];
    },
  },
};
</script>
